.table-wrapper {
  background: var(--commonWhiteBoxBg);
  border-radius: var(--commonWhiteBoxBorderRadius);
  box-shadow: var(--commonWhiteBoxShadow);
  margin-bottom: var(--verticalSpacing);
  min-height: calc(100vh - 310px);
  overflow: auto;
}
.table-wrapper.no-data-table-wrapper {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}
.top-search-section {
  min-height: auto;
  background: var(--commonWhiteBoxBg);
  border-radius: var(--commonWhiteBoxBorderRadius);
  box-shadow: var(--commonWhiteBoxShadow);
  margin-bottom: var(--verticalSpacing);
  overflow: auto;
}
.table-top-section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.table-top-section .table-search-wrapper {
  position: relative;
}
.table-top-section .table-search-wrapper input {
  padding: 8px 8px 8px 34px;
  border-radius: var(--commonWhiteBoxBorderRadius2);
  background: #fff;
  border: solid 1px var(--commonLightGreyBorder);
}

.table-top-section .table-search-wrapper select {
  padding: 8px 8px 8px 8px;
  border-radius: var(--commonWhiteBoxBorderRadius2);
  background: #fff;
  width: 100%;
  border: solid 1px var(--commonLightGreyBorder);
}

.table-top-section .table-search-wrapper svg {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.table-wrapper .data-table-wrapper {
  max-width: 100%;
  overflow: auto;
}

.table-wrapper .data-table {
  width: 100%;
  display: table;
}

.table-wrapper .data-table .t-row {
  display: table-row;
}

.table-wrapper .data-table .t-row .t-cell {
  display: table-cell;
  padding: 10px 20px;
  position: relative;
  border-bottom: solid 1px rgba(0, 0, 0, 0.06);
  vertical-align: middle;
}

.table-wrapper .data-table .t-row:last-child .t-cell {
  border-bottom: 0;
}

.table-wrapper .data-table .t-row.t-row-head .t-cell {
  background: var(--commonLightGreyBg);
  font-size: 0.85em;
  font-weight: 600;
  padding: 10px 20px;
  color: var(--commonLightGreyColor2);
  border-bottom: 0;
}

.table-wrapper .data-table .t-row.t-row-head .t-cell::before {
  content: "";
  top: 5px;
  right: 5px;
  width: 1px;
  height: calc(100% - 10px);
  position: absolute;
  background: #dbdbdb;
}

.table-wrapper .data-table .t-row.t-row-head .t-cell:last-child:before {
  display: none;
}

.table-wrapper .data-table .t-row .t-cell .t-options {
  display: flex;
  align-items: center;
}

.table-wrapper .data-table .t-row .t-cell .t-options a {
  display: flex;
  padding: 8px;
  border-radius: var(--commonWhiteBoxBorderRadius2);
  margin-right: 8px;
  border: solid 1px var(--commonLightGreyBorder);
}

.table-wrapper .data-table .t-row .t-cell .t-options a svg {
  width: 17px;
  height: 17px;
}
.table-wrapper .no-data-box {
  display: flex;
  flex-direction: column;
  max-width: 312px;
  margin: 20px;
  text-align: center;
  align-items: center;
}
.table-wrapper .no-data-box .no-record-text {
  padding: 10px 0;
  font-weight: 600;
}
.table-wrapper .no-data-box .no-record-desc {
  color: var(--commonLightGreyColor2);
}
@media only screen and (max-width: 1020px) {
  .table-top-section {
    flex-direction: column;
    align-items: flex-start;
  }
  .table-top-section .table-search-wrapper {
    margin-bottom: 10px;
  }
  .table-top-section .generic-btn {
    margin-left: auto;
  }
}
