* {
  box-sizing: border-box;
}

body,
body * {
  font-family: "Inter", sans-serif;
}

body {
  padding: 0;
  margin: 0;
  background: var(--bodyBG);
  font-size: var(--defalultFontSize);
  color: var(--defaultTextColor);
}

input,
textarea,
select {
  font-size: 14px;
}

a {
  color: inherit;
}

img,
svg {
  line-height: 0;
}

.generic-btn {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: var(--commonWhiteBoxBorderRadius2);
  background: var(--genericButtonBG);
  border: solid 1px var(--genericButtonBG);
  color: var(--genericButtonTextColor);
  text-decoration: none;
  cursor: pointer;
}

.generic-btn:hover {
  background: var(--genericButtonBGHover);
}

.generic-btn svg {
  width: 17px;
  height: 17px;
  fill: var(--genericButtonTextColor);
}

.generic-btn svg + span {
  padding-left: 7px;
}

.generic-btn.secondary {
  background: #fff;
  border: solid 1px var(--commonLightGreyBorder);
  color: var(--commonDarkGrey);
  font-weight: 600;
}

.generic-btn.secondary:hover {
  background: var(--commonDarkGrey);
  border: solid 1px var(--commonDarkGrey);
  color: #fff;
}

.generic-btn.secondary:hover svg path {
  fill: #fff;
}

.main-page-wrapper {
  margin-top: var(--headerHeight);
  margin-left: var(--navWidth);
  width: calc(100% - var(--navWidth));
  padding: var(--commonPadding);
}

.page-title {
  font-size: 1.4em;
  margin-bottom: var(--verticalSpacing);
  font-weight: 600;
  display: flex;
  align-items: center;
}

.page-title .page-back {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px 10px 10px;
}

.page-title .page-back span {
  height: 7px;
  width: 7px;
  border-style: solid;
  border-color: var(--commonLightGreyColor2);
  border-width: 0px 1px 1px 0px;
  transform: rotate(135deg);
  margin-right: -2px;
}

.page-title .page-back + .page-text {
  padding-left: 5px;
}

.no-data {
  text-align: center;
  color: #666;
  padding: 20px;
}

.no-data-image {
  width: 100%;
  height: 350px;
  display: block;
  margin: 0 auto 10px;
}

@media only screen and (max-width: 1020px) {
  .main-page-wrapper {
    margin-left: 0;
    width: 100%;
  }
}
